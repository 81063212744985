export const FareCalculatorNext = (distance, time, rateDetails, instructionData, decimal, services) => {
    let totalServiceAmount = 0;
    let hours = new Date().getHours();

    // Calculate totalServiceAmount if services exist
    if (services && services.length > 0) {
        for (let i = 0; i < services.length; i++) {
            totalServiceAmount += services[i].service_amount;
        }
    }

    // Filter and select the relevant period based on the time criteria
    const selectedPeriod = rateDetails.periods.find((period) => {
        return Number(period.from_time) < Number(hours) && Number(hours) < Number(period.to_time);
    });

    // Calculate base fare based on distance and time
    let baseCalculated = (
        parseFloat(selectedPeriod.rate_per_unit_distance) * parseFloat(distance) +
        parseFloat(selectedPeriod.rate_per_hour) * (parseFloat(time) / 3600)
    );

    // Add base fare, parcel type amount, and option amount if available
    if (selectedPeriod.base_fare > 0) {
        baseCalculated += selectedPeriod.base_fare;
    }
    if (instructionData && instructionData.parcelTypeSelected) {
        baseCalculated += instructionData.parcelTypeSelected.amount;
    }
    if (instructionData && instructionData.optionSelected) {
        baseCalculated += instructionData.optionSelected.amount;
    }

    // Ensure total meets minimum fare requirements
    let total = baseCalculated > parseFloat(selectedPeriod.min_fare) ? baseCalculated : parseFloat(selectedPeriod.min_fare);

    // Calculate convenience fee based on type and services
    let convenienceFee = 0;
    if (selectedPeriod.convenience_fee_type && selectedPeriod.convenience_fee_type === 'flat') {
        convenienceFee = services && services.length > 0 ? selectedPeriod.convenience_fee : 0;
    } else {
        convenienceFee = services && services.length > 0 ? total * parseFloat(selectedPeriod.convenience_fee) / 100 : 0;
    }

    // Calculate grand total including convenience fee and additional service amount
    let grandTotal = total + convenienceFee + totalServiceAmount;

    // Return formatted results
    return {
        totalCost: parseFloat(total.toFixed(decimal)),
        grandTotal: parseFloat(grandTotal.toFixed(decimal)),
        convenience_fees: parseFloat(convenienceFee.toFixed(decimal))
    };
};
