import React, { useState, useEffect } from 'react';
import MaterialTable from 'material-table';
import { useSelector, useDispatch } from "react-redux";
import CircularLoading from "../components/CircularLoading";
import { api } from 'common';
import { useTranslation } from "react-i18next";
import {colors} from '../components/Theme/WebTheme';
import {SECONDORY_COLOR } from "../common/sharedFunctions";

export default function AddService() {
  const { t,i18n } = useTranslation();
  const isRTL = i18n.dir();
  const {
    editService
  } = api;
  const settings = useSelector(state => state.settingsdata.settings);

  const columns = [
    { title: t('services'), field: 'services',},
    { title: t('service_amount'), field: 'service_amount', type: 'numeric',}
  ];

  const [data, setData] = useState([]);
  const servicedata = useSelector(state => state.servicedata);
  const dispatch = useDispatch();

  useEffect(() => {
    if (servicedata.service) {
      setData(servicedata.service);
    } else {
      setData([]);
    }
  }, [servicedata.service]);

  const [selectedRow, setSelectedRow] = useState(null);

  return (
    servicedata.loading ? <CircularLoading /> :
    <MaterialTable
      title={t('services_tittle')}
      columns={columns}
      style={{
        direction: isRTL === "rtl" ? "rtl" : "ltr",
        borderRadius: "8px",
        boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,
        padding: "20px",
      }}      data={data}
      onRowClick={((evt, selectedRow) => setSelectedRow(selectedRow.tableData.id))}
      options={{
        rowStyle: rowData => ({
        backgroundColor: (selectedRow === rowData.tableData.id) ? '#EEE' : '#FFF'
      }),
        editable:{
          backgroundColor: colors.Header_Text,
          fontSize: "0.8em",
          fontWeight: 'bold ',
          fontFamily: 'Lucida Console", "Courier New", monospace'
        },
        headerStyle: {
            position: "sticky",
            top: "0px",
            fontSize: "0.8em",
            fontWeight: "bold ",
            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
            color: colors.BLACK,
            backgroundColor: SECONDORY_COLOR,
            textAlign: "center",
            border: "1px solid rgba(224, 224, 224, 1)",
          },
          cellStyle: {
            border: "1px solid rgba(224, 224, 224, 1)",
            textAlign: "center",
          },
      }}
      localization={{body:{
        addTooltip: (t('add')),
        deleteTooltip: (t('delete')),
        editTooltip: (t('edit')),
        emptyDataSourceMessage: (
          (t('blank_message'))
      ),
      editRow: { 
        deleteText: (t('delete_message')),
        cancelTooltip: (t('cancel')),
        saveTooltip: (t('save')) 
        }, 
        },
        toolbar: {
          searchPlaceholder: (t('search')),
          exportTitle: (t('export')),
        },
        header: {
          actions: (t('actions')) 
      },
      pagination: {
        labelDisplayedRows: ('{from}-{to} '+ (t('of'))+ ' {count}'),
        firstTooltip: (t('first_page_tooltip')),
        previousTooltip: (t('previous_page_tooltip')),
        nextTooltip: (t('next_page_tooltip')),
        lastTooltip: (t('last_page_tooltip'))
      },
      }}
      editable={{
        onRowAdd: newData =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              if(!(newData.services &&  newData.service_amount)){
                alert(t('no_details_error'));
                reject();
              }else{
                newData['createdAt'] = new Date().getTime();
                newData['service_amount'] = newData.service_amount;
                dispatch(editService(newData,"Add"));
                resolve();
              }
            }, 600);
          }),
        onRowUpdate: (newData, oldData) =>
          settings.AllowCriticalEditsAdmin?
          new Promise((resolve, reject) => {
            setTimeout(() => {
                if(!(newData.services &&  newData.service_amount)){
                alert(t('no_details_error'));
                reject();
              }else{
                resolve();
                newData['service_amount'] = newData.service_amount;
                if(newData !== oldData){
                  delete newData.tableData;
                  dispatch(editService(newData,"Update"));
                }
              }
            }, 600);
          })
          :
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              alert(t('demo_mode'));
            }, 600);
          }),
        onRowDelete: oldData =>
          settings.AllowCriticalEditsAdmin?
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              dispatch(editService(oldData,"Delete"));
            }, 600);
          })
          :
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              alert(t('demo_mode'));
            }, 600);
          })
      }}
    />
  );
}