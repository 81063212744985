import React, { useEffect, useState } from "react";
import AlertDialog from "../components/AlertDialog";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useSelector, useDispatch } from "react-redux";
import { Typography, TextField, Button, Grid, Card } from "@mui/material";
import { api } from "common";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import CircularLoading from "components/CircularLoading";
import { useParams } from "react-router-dom";
import { colors } from "components/Theme/WebTheme";
import { MAIN_COLOR, SECONDORY_COLOR } from "../common/sharedFunctions";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  textField: {
    "& label.Mui-focused": {
      color: MAIN_COLOR,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR,
      },
    },
  },
  selectField: {
    color: "black",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: MAIN_COLOR,
    },
  },
  rootRtl_3: {
    "& label": {
      right: 17,
      left: "auto",
      paddingRight: 12,
    },
    "& legend": {
      textAlign: "right",
      marginRight: 20,
    },
    "& label.Mui-focused": {
      color: MAIN_COLOR,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR,
      },
    },
  },
  rootRtl: {
    "& label": {
      right: 20,
      left: "auto",
      paddingRight: 20,
    },
    "& legend": {
      textAlign: "right",
      marginRight: 15,
    },
    "& label.Mui-focused": {
      color: MAIN_COLOR,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR,
      },
    },
  },
  rootRtl_1: {
    "& label": {
      right: 0,
      left: "auto",
      paddingRight: 20,
    },
    "& legend": {
      textAlign: "right",
      marginRight: 30,
    },
    "& label.Mui-focused": {
      color: MAIN_COLOR,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR,
      },
    },
  },
  rootRtl_2: {
    "& label": {
      right: 17,
      left: "auto",
      paddingRight: 12,
    },
    "& legend": {
      textAlign: "right",
      marginRight: 25,
    },
    "& label.Mui-focused": {
      color: MAIN_COLOR,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR,
      },
    },
  },
  rootRtl_4: {
    "& label": {
      right: 17,
      left: "auto",
      paddingRight: 12,
    },
    "& legend": {
      textAlign: "right",
      marginRight: 15,
    },
    "& label.Mui-focused": {
      color: MAIN_COLOR,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR,
      },
    },
  },
  rootRtl_5: {
    "& label": {
      right: 17,
      left: "auto",
      paddingRight: 12,
    },
    "& legend": {
      textAlign: "right",
      marginRight: 25,
    },
    "& label.Mui-focused": {
      color: MAIN_COLOR,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiFilledInput-underline:after": {
      borderBottomColor: MAIN_COLOR,
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: MAIN_COLOR,
      },
    },
  },
  selectField_rtl: {
    color: "black",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: MAIN_COLOR,
    },
    "& label": {
      right: 0,
      left: "auto",
    },
    "& legend": {
      textAlign: "right",
      marginRight: 35,
    },
  },

  right: {
    textAlign: "right",
    right: 0,
    left: "auto",
    paddingRight: 40,
  },
}));
const EditCarType = () => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const isRTL = i18n.dir();
  const settings = useSelector((state) => state.settingsdata.settings);
  const cartypes = useSelector((state) => state.cartypes);
  const carlistdata = useSelector((state) => state.carlistdata);
  const staticusers = useSelector((state) => state.usersdata.staticusers);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loding, setLoding] = useState(false);
  const [commonAlert, setCommonAlert] = useState({ open: false, msg: "" });
  const { editCarType, updateUserCar, editCar } = api;
  const [oldData, setOldData] = useState(null);
  const [data, setData] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    if (id) {
      if (cartypes && cartypes.cars) {
        const carData = cartypes.cars.filter(
          (item) => item.id === id.toString()
        )[0];
        if (!carData) {
          navigate("/404");
        }
        setData(carData);
        setOldData(carData);
      }
    } else {
      setData({
        name: "",
        pos: "",
        image:
          "https://cdn.pixabay.com/photo/2012/04/15/22/09/car-35502__480.png",
        extra_info: "",
        periods: [
          {
            period_name: "",
            from_time: "",
            to_time: "",
            base_fare: 0,
            min_fare: 0,
            convenience_fee_type: "flat",
            convenience_fee: 0,
            rate_per_hour: 0,
            rate_per_unit_distance: 0,
            fleet_admin_fee: 0,
          },
        ],
      });
    }
  }, [cartypes, cartypes.cars, id, navigate]);

  const handleInputChange = (e) => {
    const value = e.target.type === 'number' ? Number(e.target.value) : e.target.value;
    if(e.target.ariaLabel === null){
      setData({ ...data, [e.target.id]: value });
    } else if (e.target.ariaLabel === undefined){
      setData({...data, [e.target.name]: value})
    } else {
      const index = Number(e.target.ariaColIndex);
      const { ariaLabel, id } = e.target;
      const state = data;
      state[ariaLabel][index][id] = value
      setData({...data, data: state});
    }
  };

  const handleCommonAlertClose = (e) => {
    e.preventDefault();
    setCommonAlert({ open: false, msg: "" });
  };
  const handleAddPeriod = () => {
    setData(data => ({
      periods: [...data.periods, {
        period_name: "",
        from_time: "",
        to_time: "",
        base_fare: 0,
        min_fare: 0,
        convenience_fee_type: "flat",
        convenience_fee: 0,
        rate_per_hour: 0,
        rate_per_unit_distance: 0,
        fleet_admin_fee: 0,
      }]
    }));
  };

  const handleSelectChange = () => {
    console.log("Coming Soon");
  }

  const handleRemovePeriod = (index) => {
    const values = [...data.periods];
    values.splice(index, 1);
    setData(values);
  };
  const handleSubmit = () => {
    if (!id) {
      if (
        !(
          data &&
          data.name &&
          data.pos 
        )
      ) {
        if (!(data && data.name)) {
          setCommonAlert({ open: true, msg: t("proper_input_name") });
        } else if (!data.pos) {
          setCommonAlert({ open: true, msg: t("position_required") });
        }
      } else {
        settings.AllowCriticalEditsAdmin
          ? new Promise((resolve, reject) => {
              setLoding(true);
              setTimeout(() => {
                if (data && data.name) {
                  data["createdAt"] = new Date().getTime();
                  dispatch(editCarType(data, "Add"));
                  resolve();
                  setLoding(false);
                  navigate("/cartypes");
                } else {
                  setCommonAlert({ open: true, msg: t("proper_input_name") });
                  reject(new Error("Enter proper name"));
                  setLoding(false);
                }
              }, 600);
            })
          : new Promise((resolve) => {
              setTimeout(() => {
                resolve();
                setCommonAlert({ open: true, msg: t("demo_mode") });
              }, 600);
            });
      }
    } else if (id) {
      settings.AllowCriticalEditsAdmin
        ? new Promise((resolve) => {
            setLoding(true);
            setTimeout(() => {
              resolve();
              if (data !== oldData) {
                delete data.tableData;
                if (data.name !== oldData.name) {
                  let users = staticusers?.filter(
                    (user) =>
                      user.usertype === "driver" &&
                      user.carType === oldData.name
                  );
                  for (let i = 0; i < users?.length; i++) {
                    dispatch(
                      updateUserCar(users[i].id, {
                        carType: data.name,
                      })
                    );
                  }
                  let cars = carlistdata.cars.filter(
                    (car) => car.carType === oldData.name
                  );
                  for (let i = 0; i < cars.length; i++) {
                    dispatch(
                      editCar({ ...cars[i], carType: data.name }, "Update")
                    );
                  }
                }
                dispatch(editCarType(data, "Update"));
                navigate("/cartypes");
                setLoding(false);
              } else {
                setLoding(false);
                setCommonAlert({
                  open: true,
                  msg: t("make_changes_to_update"),
                });
              }
            }, 600);
          })
        : new Promise((resolve) => {
            setTimeout(() => {
              resolve();
              alert(t("demo_mode"));
            }, 600);
          });
    }
  };

  return loding ? (
    <CircularLoading />
  ) : (
    <div>
      <Card
        style={{
          borderRadius: "19px",
          backgroundColor: colors.WHITE,
          minHeight: 100,
          maxWidth: "75vw",
          marginTop: 20,
          marginBottom: 20,
          padding: 25,
          boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,
        }}
      >
        <Typography
          variant="h5"
          style={{
            margin: "10px 10px 0 5px",
            textAlign: isRTL === "rtl" ? "right" : "left",
          }}
        >
          {id ? t("update_carType_title") : t("add_carType_title")}
        </Typography>
        <div
          dir={isRTL === "rtl" ? "rtl" : "ltr"}
          style={{
            marginBottom: 20,
          }}
        >
          <Button
            variant="text"
            onClick={() => {
              navigate("/cartypes");
            }}
          >
            <Typography
              style={{
                margin: "10px 10px 0 5px",
                textAlign: isRTL === "rtl" ? "right" : "left",
                fontWeight: "bold",
                color: MAIN_COLOR,
              }}
            >
              {`<<- ${t("go_back")}`}
            </Typography>
          </Button>
        </div>
        <Grid
          container
          spacing={2}
          sx={{
            gridTemplateColumns: "50%",
            direction: isRTL === "rtl" ? "rtl" : "ltr",
          }}
        >
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <TextField
              label={t("name")}
              id="name"
              value={data?.name || ""}
              fullWidth
              onChange={handleInputChange}
              className={isRTL === "rtl" ? classes.rootRtl : classes.textField}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <TextField
              label={t("position")}
              id="pos"
              value={data?.pos || 0}
              type="number"
              fullWidth
              onChange={handleInputChange}
              className={
                isRTL === "rtl" ? classes.rootRtl_4 : classes.textField
              }
            />
          </Grid>

          {data?.periods.map((period, index) => (
            <Grid item xs={12} sm={12} key={index}>
              <Grid
                container
                spacing={2}
                sx={{
                  gridTemplateColumns: "50%",
                  direction: isRTL === "rtl" ? "rtl" : "ltr",
                }}
              >
                <Grid item xs={10}>
                  <Typography
                    variant="h5"
                    style={{
                      textAlign: isRTL === "rtl" ? "right" : "left",
                    }}
                  >
                    {"Price for Period -" + (index + 1)}
                  </Typography>
                </Grid>
                <Grid item xs={2} style={{ textAlign: "right" }}>
                  {data?.periods.length > 1 && (
                    <Button
                      style={{
                        borderRadius: "19px",
                        backgroundColor: "red",
                        minHeight: 30,
                        minWidth: 30,
                        textAlign: "center",
                      }}
                      onClick={handleRemovePeriod}
                      variant="contained"
                    >
                      <Typography
                        style={{
                          color: colors.WHITE,
                          textAlign: "center",
                          fontSize: 14,
                        }}
                      >
                        {"x"}
                      </Typography>
                    </Button>
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <TextField
                    inputProps={{
                      "aria-label": "periods",
                      "aria-colindex" : index
                    }}
                    label={"Period Name"}
                    id="period_name"
                    value={period?.period_name}
                    fullWidth
                    type="text"
                    onChange={handleInputChange}
                    className={
                      isRTL === "rtl" ? classes.rootRtl_3 : classes.textField
                    }
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <TextField
                    inputProps={{
                      "aria-label": "periods",
                      "aria-colindex" : index
                    }}
                    label={"From"}
                    id="from_time"
                    value={period?.from_time}
                    fullWidth
                    type="text"
                    onChange={handleInputChange}
                    className={
                      isRTL === "rtl" ? classes.rootRtl_3 : classes.textField
                    }
                  />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <TextField
                    inputProps={{
                      "aria-label": "periods",
                      "aria-colindex" : index
                    }}
                    label={"To"}
                    id="to_time"
                    value={period?.to_time}
                    fullWidth
                    type="text"
                    onChange={handleInputChange}
                    className={
                      isRTL === "rtl" ? classes.rootRtl_3 : classes.textField
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <TextField
                    inputProps={{
                      "aria-label": "periods",
                      "aria-colindex" : index
                    }}
                    label={t("base_fare")}
                    id="base_fare"
                    value={period?.base_fare || 0}
                    fullWidth
                    type="number"
                    onChange={handleInputChange}
                    className={
                      isRTL === "rtl" ? classes.rootRtl_3 : classes.textField
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={6}
                  xl={6}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <FormControl
                    fullWidth
                    style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                  >
                    <InputLabel
                      id="demo-simple-select-label"
                      className={isRTL === "rtl" ? classes.right : ""}
                      sx={{ "&.Mui-focused": { color: MAIN_COLOR } }}
                    >
                      {t("convenience_fee_type")}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      name="convenience_fee_type"
                      value={period?.convenience_fee_type || ""}
                      label={t("convenience_fee_type")}
                      onChange={handleSelectChange}
                      className={
                        isRTL === "rtl"
                          ? classes.selectField_rtl
                          : classes.selectField
                      }
                    >
                      <MenuItem
                        style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                        value={"flat"}
                      >
                        {t("flat")}
                      </MenuItem>
                      <MenuItem
                        style={{ direction: isRTL === "rtl" ? "rtl" : "ltr" }}
                        value={"percentage"}
                      >
                        {t("percentage")}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <TextField
                    inputProps={{
                      "aria-label": "periods",
                      "aria-colindex" : index
                    }}
                    label={t("convenience_fee")}
                    id="convenience_fees"
                    value={period?.convenience_fees || 0}
                    type="number"
                    fullWidth
                    onChange={handleInputChange}
                    className={
                      isRTL === "rtl" ? classes.rootRtl_4 : classes.textField
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <TextField
                    inputProps={{
                      "aria-label": "periods",
                      "aria-colindex" : index
                    }}
                    label={t("rate_per_hour")}
                    id="rate_per_hour"
                    value={period?.rate_per_hour || 0}
                    type="number"
                    fullWidth
                    onChange={handleInputChange}
                    className={
                      isRTL === "rtl" ? classes.rootRtl_3 : classes.textField
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <TextField
                    inputProps={{
                      "aria-label": "periods",
                      "aria-colindex" : index
                    }}
                    label={t("min_fare")}
                    id="min_fare"
                    value={period?.min_fare || 0}
                    type="number"
                    fullWidth
                    onChange={handleInputChange}
                    className={
                      isRTL === "rtl" ? classes.rootRtl_2 : classes.textField
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <TextField
                    inputProps={{
                      "aria-label": "periods",
                      "aria-colindex" : index
                    }}
                    label={t("rate_per_unit_distance")}
                    id="rate_per_unit_distance"
                    value={period?.rate_per_unit_distance || 0}
                    type="number"
                    fullWidth
                    onChange={handleInputChange}
                    className={
                      isRTL === "rtl" ? classes.rootRtl_1 : classes.textField
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <TextField
                    inputProps={{
                      "aria-label": "periods",
                      "aria-colindex" : index
                    }}
                    label={t("fleet_admin_fee")}
                    id="fleet_admin_fee"
                    value={period?.fleet_admin_fee || 0}
                    type="number"
                    fullWidth
                    onChange={handleInputChange}
                    className={
                      isRTL === "rtl" ? classes.rootRtl_5 : classes.textField
                    }
                  />
                </Grid>
                {data?.periods.length === index + 1 && (
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Button
                      style={{
                        borderRadius: "19px",
                        backgroundColor: "green",
                        minHeight: 50,
                        width: "200px",
                        textAlign: "center",
                        boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,
                      }}
                      onClick={handleAddPeriod}
                      variant="contained"
                    >
                      <Typography
                        style={{
                          color: colors.WHITE,
                          textAlign: "center",
                          fontSize: 14,
                          fontWeight: "bold",
                        }}
                      >
                        {"Add Period"}
                      </Typography>
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          ))}
          <Grid item xs={12} sm={12}>
            <TextField
              label={t("extra_info")}
              id="extra_info"
              type="text"
              value={data?.extra_info || ""}
              fullWidth
              onChange={handleInputChange}
              className={
                isRTL === "rtl" ? classes.rootRtl_3 : classes.textField
              }
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                borderRadius: "19px",
                backgroundColor: MAIN_COLOR,
                minHeight: 50,
                minWidth: "50%",
                textAlign: "center",
                boxShadow: `0px 2px 5px ${SECONDORY_COLOR}`,
              }}
              onClick={handleSubmit}
              variant="contained"
            >
              <Typography
                style={{
                  color: colors.WHITE,
                  textAlign: "center",
                  fontSize: 16,
                  fontWeight: "bold",
                }}
              >
                {id ? t("update") : t("submit")}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Card>
      <AlertDialog open={commonAlert.open} onClose={handleCommonAlertClose}>
        {commonAlert.msg}
      </AlertDialog>
    </div>
  );
};

export default EditCarType;
