export const FirebaseConfig = {
	"projectId": "taxi-app-dev-b492d",
	"appId": "1:478862796502:web:8ab7bde87675e91db1cb77",
	"databaseURL": "https://taxi-app-dev-b492d-default-rtdb.firebaseio.com",
	"storageBucket": "taxi-app-dev-b492d.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyCYKrpri5vnzwjzFAeLxU1i-wN6rOFTKWE",
	"authDomain": "taxi-app-dev-b492d.firebaseapp.com",
	"messagingSenderId": "478862796502",
	"measurementId": "G-2W8GVF0RRW"
};