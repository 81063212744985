import { 
    FETCH_SERVICE,
    FETCH_SERVICE_SUCCESS,
    FETCH_SERVICE_FAILED,
    EDIT_SERVICE
  } from "../store/types";

  export const INITIAL_STATE = {
    service:null,
    loading: false,
    error:{
      flag:false,
      msg: null
    }
  }

  export const servicereducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case FETCH_SERVICE:
        return {
          ...state,
          loading:true
        };
      case FETCH_SERVICE_SUCCESS:
        return {
          ...state,
          service:action.payload,
          loading:false
        };
      case FETCH_SERVICE_FAILED:
        return {
          ...state,
          service:null,
          loading:false,
          error:{
            flag:true,
            msg:action.payload
          }
        };
      case EDIT_SERVICE:
        return state;
      default:
        return state;
    }
  };